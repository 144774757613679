@import "./utils";
@import "~jason-css/src/responsive/index";
@import "../fonts/iconfont.css";

@font-face {
  font-family: 'lato';
  src: url('../fonts/Lato-Regular.eot');
  src: url('../fonts/Lato-Regular.woff') format('woff'),
    url('../fonts/Lato-Regular.ttf') format('truetype'),
    url('../fonts/Lato-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'lato-black';
  src: url('../fonts/Lato-Black.eot');
  src: url('../fonts/Lato-Black.woff') format('woff'),
    url('../fonts/Lato-Black.ttf') format('truetype'),
    url('../fonts/Lato-Black.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

*{
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  outline:none;
}


body {
  font-family: 'lato', '微软雅黑';
  line-height: 1.2;
  position: relative;
  color: $color-text;
  background-color: #fff;
  overflow-x: hidden;
  @include res(font-size, $text-size-base, $text-size-ratio);
}

.comp-root{
  overflow-x: hidden;
}

//清除浮动
.clear {
  zoom: 1;

  &::after {
    content: '';
    display: block;
    height: 0;
    visibility: hidden;
    clear: both;
  }
}


//主体内容部分宽度
.container {
  box-sizing: content-box;
  width: 100%;
  max-width: 1680px;
  @include res(padding-left, $container-gutter-base, $container-gutter-ratio);
  @include res(padding-right, $container-gutter-base, $container-gutter-ratio);

  &.container-1200 {
    max-width: 1200px;
  }
}

@media screen and (max-width: 1680px) {
  .container {
    box-sizing: border-box;
    max-width: 1680px;
  }
}

@media screen and (max-width: 1440px) {
  .container {
    max-width: 1440px;
  }
}
@media screen and (max-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media screen and (max-width: 768px) {
  .container {
    max-width: 768px;
  }
}

.page{
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  @include res(font-size,24px, 16 / 24);
  @include res(margin-top,90px, 30 / 90);
  a{
    display: block;
    transition: all .3s;
    border-bottom-style: solid;
    border-bottom-color: transparent;
    color: #000;
    @include res(border-bottom-width,4px, 2 / 4);
    @include res(margin-left,15px, 8 / 15);
    @include res(margin-right,15px, 8 / 15);
    @include res(padding-left,5px, 3 / 5);
    @include res(padding-right,5px, 3 / 5);
    @include res(padding-bottom,3px, 2 / 3);
    &:hover{
      color: $color-main;
    }
    &.active{
      color: #000;
      border-bottom-color: $color-main;
    }
  }
}

//公共面包屑
.common-location{
  display: flex;
  align-items: center;
  text-transform: uppercase;
  white-space: nowrap;
  @include res(padding-top,50px, 15 / 50);
  @include res(margin-bottom,80px, 15 / 80);
  a{
    transition: all .3s;
    &:hover{
      color: $color-main;
    }
  }
  span{
    display: block;
    @include res(margin-left,5px, 3 / 5);
    @include res(margin-right,5px, 3 / 5);
  }
}

//内页公共导航部分
.common-nav{
  background-color: #eeeeee;
  @include res(margin-bottom,120px, 20 / 120);
  .container{
    align-items: stretch;
    justify-content: space-between;
    @include res(display,flex,(xs:block));
    .nav-left{
      flex-shrink: 0;
      @include res(width,67%,(xs:100%));
      @include res(padding-bottom,30px, 10 / 30);
      .nav-title{
        color: #000;
        white-space: nowrap;
        @include res(font-size,58px, 26 / 58);
        b{
            position: relative;
            display: inline-block;
            span{
                position: relative;
                z-index: 1;
            }
            &::after{
                content: '';
                display: block;
                width: 100%;
                background-color: $color-main;
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 0;
                @include res(height,23px, 10 / 23);
            }
        }
      }
    }
    .nav-right{
      flex-shrink: 0;
      background-color: $color-main;
      position: relative;
      @include res(width,33%,(xs:100%));
      &::after{
        content: '';
        display: block;
        width: 150%;
        height: 100%;
        background-color: $color-main;
        position: absolute;
        top: 0;
        z-index: 0;
        @include res(left,99%,(xs:-25%));
      }
      .link{
        position: relative;
        z-index: 1;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        overflow-x: auto;
        white-space: nowrap;
        @include res(flex-direction,column,(xs:row));
        @include res(padding-top,55px, 0 / 55);
        @include res(padding-bottom,45px, 0 / 45);
        @include res(padding-left,60px, 10 / 60);
        @include res(padding-right,10px, 10 / 10);
        a{
          position: relative;
          font-weight: bold;
          transition: all .3s;
          @include res(font-size,24px, 16 / 24);
          @include res(padding-left,25px,(xs:0));
          @include res(margin-bottom,10px,(xs:0));
          @include res(padding-top,0,(xs:10px));
          @include res(padding-bottom,0,(xs:15px));
          &:not(:last-child){
            @include res(margin-right,0,(xs:15px));
          }
          &::after{
            content: '';
            display: block;
            border-radius: 50%;
            position: absolute;
            background-color: #000;
            opacity: 0;
            @include res(top,calc(50% - 3px),(xs:75%));
            @include res(left,0,(xs:calc(50% - 2px)));
            @include res(width,6px,(xs:4px));
            @include res(height,6px,(xs:4px));
          }
          &:hover{
            @include res(padding-left,30px,(xs:0));
          }
          &.active{
            &::after{
              opacity: 1;
            }
          }
        }
      }
    }
  }
}


//产品列表公共样式
.product-list{
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  // @include res(border-left,1px solid #dcdcdc,(xs:none));
  @include res(display,flex);
  li{
      flex-shrink: 0;
      border-right: 1px solid #dcdcdc;
      position: relative;
      box-sizing: border-box;
      position: relative;
      transition: all .5s;
      z-index: 1;
      @include res(border-bottom,none,(md:1px solid #dcdcdc));
      @include res(border-left,none,(xs:1px solid #dcdcdc));
      @include res(width,33.33%,(md:50%,xs:100%));
      &:hover{
          z-index: 2;
          box-shadow: 0px 0px 20px rgba(0,0,0,.2);
          border-color: $color-main !important;
          .pro-cont1{
              background-color: $color-main;
              &::after{
                background-color: transparent;
              }
          }
          .pro-cont2{
              box-shadow: 0px 0px 20px rgba(0,0,0,.2);
              @include res(transform,translateY(100%),(md:translateY(0)));
          }
      }
      &:nth-child(3n+1){
          @include res(border-left,1px solid #dcdcdc,(md:none,xs:1px solid #dcdcdc));
      }
      &:nth-child(2n+1){
          @include res(border-left,null,(md:1px solid #dcdcdc));
      }
      &:nth-child(1){
          border-top: 1px solid #dcdcdc;
      }
      &:nth-child(2){
          @include res(border-top,1px solid #dcdcdc,(xs:none));
      }
      &:nth-child(3){
          @include res(border-top,1px solid #dcdcdc,(md:none));
      }
      .pro-cont1{
          background-color: #fff;
          transition: all .3s;
          position: relative;
          display: block;
          &::after{
            content: '';
            width: 100%;
            height: 1px;
            background-color: #dcdcdc;
            position: absolute;
            bottom: 0;
            left: 0;
            transition: all .3s;
            @include res(display,block,(md:none));
          }
          .item-text{
              @include res(padding-top,40px, 20 / 40);
              @include res(padding-left,60px, 10 / 60);
              @include res(padding-right,60px, 10 / 60);
              @include res(padding-bottom,20px, 10 / 20);
              .item-title1{
                  color: #000;
                  font-family: 'lato-black';
                  white-space: nowrap;
                  @include res(font-size,60px, 30 / 60);
              }
              .item-title2{
                  color: #000;
                  white-space: nowrap;
                  font-weight: bold;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 1;
                  overflow: hidden;
                  // @include res(height,28px,19 / 28);
                  @include res(font-size,24px, 16 / 24);
                  // @include res(margin-top,5px, 5 / 5);
                  @include res(margin-bottom,10px, 5 / 10);
                  @include res(min-height,28px, 19 / 28);
              }
              .item-desc{
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                  overflow: hidden;
                  color: #4f4644;
                  @include res(min-height,38px,32 / 38);
              }
          }
          .item-img{
              overflow: hidden;
              position: relative;
              @include res(padding-left,20px, 10 / 20);
              @include res(padding-right,20px, 10 / 20);
              @include res(height,245px,180 / 245);
              img{
                  display: none;
                  width: auto;
                  height: auto;
                  max-width: 90%;
                  max-height: 100%;
                  position: absolute;
                  // top: 0;
                  left: 5%;
                  bottom: 0;
              }
          }
      }
      .pro-cont2{
          bottom: 0;
          left: 0;
          width: 100%;
          z-index: -1;
          background-color: #ededed;
          transition: all .5s;
          display: flex;
          align-items: stretch;
          justify-content: space-between;
          @include res(position,absolute,(md:static));
          .cont-left{
              flex-shrink: 0;
              display: flex;
              align-items: center;
              justify-content: space-between;
              box-sizing: border-box;
              color: #000;
              @include res(padding-top,15px, 15 / 15);
              @include res(padding-bottom,10px, 10 / 10);
              @include res(padding-left,25px, 10 / 25);
              @include res(padding-right,25px, 10 / 25);
              @include res(width,50%);
              .left-title{
                  @include res(font-size,18px, 14 / 18);
              }
              .left-list{
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                  .item{
                      cursor: pointer;
                      text-align: center;
                      @include res(width,25px, 22 / 25);
                      &::before{
                          content: '';
                          display: block;
                          background-color: #000;
                          border-radius: 50%;
                          margin: 0 auto;
                          transition: all .3s;
                          @include res(width,12px, 6 / 12);
                          @include res(height,12px, 6 / 12);
                          @include res(margin-bottom,10px, 5 / 10);
                      }
                      &:not(:last-child){
                          @include res(margin-right,10px);
                      }
                      &.active{
                          &::before{
                              background-color: $color-main;
                          }
                      }
                  }
              }
          }
          .cont-right{
              flex-shrink: 0;
              background-color: #000000;
              transition: all .3s;
              color: $color-main;
              @include res(width,50%);
              &:hover{
                a b span{
                  transform: translateY(-100%);
                }
              }
              a{
                  display: flex;
                  height: 100%;
                  align-items: center;
                  justify-content: center;
                  font-family: 'lato-black';
                  position: relative;
                  @include res(font-size,20px, 16 / 20);
                  b{
                    font-weight: normal;
                    display: inline-block;
                    overflow: hidden;
                    @include res(height,24px,19 / 24);
                    span{
                      display: block;
                      transition: all .3s;
                    }
                }
              }
          }
      }
  }
  .more{
      box-sizing: border-box;
      align-items: center;
      justify-content: flex-end;
      @include res(display,flex);
      @include res(width,33.33%,(md:50%,xs:100%));
      @include res(padding-right,50px, 10 / 50);
      &.hide{
        opacity: 0;
        visibility: hidden;
      }
      a{
          display: flex;
          align-items: center;
          @include res(margin-top,150px, 30 / 150);
          @include res(margin-bottom,150px, 30 / 150);
          &:hover{
              .text{
                  transform: translateX(10px);
              }
          }
          .icon{
              @include res(margin-right,25px, 15 / 25);
              img{
                  display: block;
                  @include res(width,48px, 30 / 48);
              }
              
          }
          .text{
              color: #231815;
              font-family: 'lato-black';
              line-height: .8;
              transition: all .3s;
              @include res(font-size,30px, 18 / 30);
              span{
                  display: inline-block;
                  position: relative;
                  @include res(margin-bottom,5px, 2 / 5);
                  &::after{
                      content: '';
                      display: block;
                      width: 100%;
                      background-color: $color-main;
                      position: absolute;
                      bottom: 0;
                      left: 0;
                      z-index: -1;
                      @include res(height,10px,5 / 10);
                  }
              }
          }
      }
  }
  .no-pro{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include res(height,500px, 200 / 500);
    .icon{
      border-radius: 50%;
      color: #fff;
      background-color: $color-main;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      @include res(width,50px, 50 / 50);
      @include res(height,50px, 50 / 50);
      .iconfont{
        @include res(font-size,30px, 30 / 30);
      }
    }
    .text{
      color: #666160;
      text-transform: uppercase;
      @include res(font-size,20px , 16 / 20);
    }
  }
}