@import "../../assets/styles/utils";

.footer{
    background-color: #000;
    position: relative;
    .container{
        align-items: stretch;
        justify-content: space-between;
        @include res(display,flex,(sm:block));
        .footer-left{
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            @include res(justify-content,flex-start,(sm:center));
            @include res(width,34%,(mmd:30%,sm:100%));
            @include res(padding-top,0,(sm:30px,xs:20px));
            @include res(padding-bottom,0,(sm:30px,xs:20px));
            img{
                display: block;
                max-width: 337px;
                height: auto;
                @include res(width,60%);
                @include res(margin-left,55px,(mmd:30px,sm:0));
            }
        }
        .footer-right{
            flex-shrink: 0;
            @include res(width,66%,(mmd:70%,sm:100%));
            @include res(border-left,1px solid rgba(255,255,255,.2),(sm:none));
            .info{
                align-items: flex-start;
                justify-content: space-between;
                @include res(display,flex,(xs:block));
                @include res(padding-left,80px, 0 / 80);
                @include res(padding-right,240px, 0 / 240);
                @include res(padding-top,60px,15 / 60);
                @include res(padding-bottom,60px, 15 / 60);
                .info-title{
                    color: #fff;
                    font-weight: bold;
                    text-transform: uppercase;
                    @include res(font-size,28px, 18 / 28);
                    @include res(margin-bottom,25px, 10 / 25);
                    @include res(text-align,null,(xs:center));
                }
                .contact{
                    flex-shrink: 0;
                    @include res(text-align,null,(xs:center));
                    @include res(margin-right,15px);
                    @include res(max-width,400px,(xs:100%));
                    p{
                        color: $color-main;
                        line-height: 1.6;
                        font-weight: lighter;
                        @include res(font-size, 18px, 14 / 18);
                    }
                }
                .link{
                    flex-shrink: 0;
                    @include res(text-align,null,(xs:center));
                    @include res(margin-top,0,(xs:20px));
                    .cont{
                        .iconfont{
                            color: $color-main;
                            @include res(font-size,42px, 30 / 42);
                            @include res(margin-right,10px, 5 / 10);
                        }
                    }
                }
            }
            .copyright{
                color: #000;
                background-color: $color-main;
                align-items: center;
                justify-content: space-between;
                position: relative;
                @include res(text-align,null,(xs:center));
                @include res(display,flex,(xs:block));
                @include res(padding-left,80px, 0 / 80);
                @include res(padding-top,25px, 10 / 25);
                @include res(padding-bottom,25px, 10 / 25);
                &::after{
                    content: '';
                    display: block;
                    width: 200%;
                    height: 100%;
                    top: 0;
                    background-color: $color-main;
                    z-index: 0;
                    position: absolute;
                    @include res(left,0,(sm:-50%));
                }
                .left{
                    position: relative;
                    z-index: 1;
                }
                .right{
                    position: relative;
                    z-index: 1;
                    display: flex;
                    align-items: center;
                    @include res(justify-content,flex-end,(xs:center));
                    a{
                        transition: all .3s;
                        &:hover{
                            color: rgba(0,0,0,.7);
                        }
                    }
                    .site-map{
                        @include res(display,null,(md:none));
                    }
                    span{
                        display: inline-block;
                        @include res(margin-left,5px, 3 / 5);
                        @include res(margin-right,5px, 3 / 5);
                    }
                }
            }
        }
    }
    .back-top{
        position: fixed;
        cursor: pointer;
        z-index: 2;
        bottom: 100px;
        opacity: 0;
        visibility: hidden;
        transition: all .3s;
        @include res(width,60px, 40 / 60);
        @include res(right,120px, 10 / 120);
        &:hover{
            .iconfont{
                transform: translateY(-3px);
            }
        }
        &.show{
            opacity: 1;
            visibility: visible;
        }
        &.abs{
            position: absolute;
            bottom: auto;
            @include res(top,-30px, -20 / -30);
        }
        .icon{
            background-color:#000;
            color: $color-main;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all .3s;
            @include res(height,30px, 20 / 30);
            .iconfont{
                transition: all .3s;
            }
        }
        .text{
            background-color: $color-main;
            display: flex;
            align-items: center;
            justify-content: center;
            @include res(height,30px, 20 / 30);
        }
    }
}