@import "../../assets/styles/utils";

.nybanner{
    display: flex;
    align-items: center;
    text-align: right;
    position: relative;
    overflow: hidden;
    @include res(height,570px,(md:400px,sm:300px,xs:200px));
    &::after{
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.5);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
    }
    b{
        display: block;
        width: 100%;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }
    .container{
        position: relative;
        z-index: 1;
        h1{
            font-family: 'lato-black';
            color: $color-main;
            @include res(font-size,100px, 34 / 100);
        }
        h2{
            font-weight: bold;
            color: #fff;
            @include res(font-size,40px, 18 / 40);
        }
    }
    #nav-md{
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        @include res(height,$header-height-base,$header-height-ratio);
    }
}