@import "../../assets/styles/utils";

header{
    &.scroll-header{
        .header-placeholder,.header{
            @include res(height,100px,60 / 100);
        }
        .mob-nav{
            @include res(padding-top,100px,60 / 100);
        }
    }
    .header-placeholder{
        transition: all .3s;
        @include res(height,$header-height-base,$header-height-ratio);
    }
    .header{
        position: fixed;
        width: 100%;
        z-index: 100;
        top: 0;
        left: 0;
        background-color: #fff;
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        box-sizing: border-box;
        transition: all .3s;
        @include res(padding-right,50px, 10 / 50);
        @include res(height,$header-height-base,$header-height-ratio);
        &.tran{
            transform: translateY(-100%);
        }
        &.hide{
            display: none;
        }
        .header-left{
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .logo{
                height: 100%;
                background-color: $color-main;
                a{
                    height: 100%;
                    display: flex;
                    align-items: center;
                    @include res(padding-left,45px, 10 / 45);
                    @include res(padding-right,45px, 10 / 45);
                    img{
                        display: block;
                        height: 59px;
                        max-height: 50%;
                        width: auto;
                    }
                }
            }
            .nav{
                height: 100%;
                text-transform: uppercase;
                @include res(display,block,(sm:none));
                @include res(margin-left,50px, 0 / 50);
                ul{
                    height: 100%;
                    display: flex;
                    align-items: stretch;
                    justify-content: flex-start;
                    li{
                        height: 100%;
                        @include res(margin-left,10px, 0 / 10);
                        // @include res(margin-left,20px, 0 / 20);
                        &.active{
                            a span{
                                color: $color-main;
                                &::after{
                                    opacity: 1;
                                }
                            }
                        }
                        a{
                            height: 100%;
                            display: flex;
                            align-items: center;
                            font-family: 'lato-black';
                            white-space: nowrap;
                            @include res(padding-left,15px, 0 / 15);
                            @include res(padding-right,15px, 0 / 15);
                            // @include res(padding-left,20px, 0 / 20);
                            // @include res(padding-right,20px, 0 / 20);
                            @include res(font-size,24px, 12 / 24);
                            span{
                                color:#000;
                                display: inline-block;
                                position: relative;
                                transition: all .3s;
                                &::after{
                                    content: '';
                                    display: block;
                                    width: 6px;
                                    height: 6px;
                                    background-color: $color-main;
                                    position: absolute;
                                    left: calc(50% - 3px);
                                    top: 120%;
                                    border-radius: 50%;
                                    opacity: 0;
                                }
                            }
                            &:hover{
                                span{
                                    color: $color-main;
                                }
                            }
                        }
                    }
                }
            }
        }
        .header-right{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .global{
                @include res(margin-right,30px, 10 / 30);
                a{
                    display: flex;
                    align-items: center;
                    &:hover{
                        span{
                            color: $color-main;
                        }
                    }
                    .iconfont{
                        @include res(font-size,20px, 18 / 20);
                        @include res(margin-right,5px, 3 / 5);
                    }
                    span{
                        color: #000;
                        display: inline-block;
                        font-weight: bold;
                        position: relative;
                        transition: all .3s;
                        @include res(padding-bottom, 3px, 1 / 3);
                        &::after{
                            content: '';
                            display: block;
                            width: 100%;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            z-index: -1;
                            background-color: $color-main;
                            @include res(height,3px, 1 / 3);
                        }
                    }
                }
            }
            .language{
                position: relative;
                &:hover{
                    .now-language{
                        border-bottom-left-radius: 0px;
                        border-bottom-right-radius: 0px;
                        border-bottom: 1px solid transparent;
                    }
                    .other-language{
                        opacity: 1;
                        visibility: visible;
                    }
                }
                .now-language{
                    border: 1px solid #a0a0a0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    color: #000;
                    border-top-left-radius: 15px;
                    border-top-right-radius: 15px;
                    border-bottom-left-radius: 15px;
                    border-bottom-right-radius: 15px;
                    transition: all .3s;
                    @include res(padding-left,20px, 10 / 20);
                    @include res(padding-right,10px, 5 / 10);
                    .iconfont{
                        color: $color-main;
                        @include res(font-size,20px, 16 / 20);
                    }
                }
                .other-language{
                    position: absolute;
                    top: calc(100% - 1px);
                    left: 0;
                    width: 100%;
                    background-color: #fff;
                    color: #000;
                    opacity: 0;
                    visibility: hidden;
                    transition: all .3s;
                    border: 1px solid #a0a0a0;
                    border-top: none;
                    border-bottom-left-radius: 15px;
                    border-bottom-right-radius: 15px;
                    overflow: hidden;
                    // @include res(padding-top,3px, 2 / 3);
                    // @include res(padding-bottom,3px, 2 / 3);
                    a{
                        display: block;
                        // text-align: center;
                        transition: all .3s;
                        padding: 3px 0;
                        @include res(padding-left,20px, 10 / 20);
                        &:hover{
                            background-color: $color-main;
                        }
                    }
                }
            }
            .hamburger{
                @include res(display,none,(sm:block));
                @include res(margin-left,30px, 10 / 30);
            }
        }
    }
    .mob-nav{
        height: 100vh;
        width: 100%;
        position: fixed;
        top: 0;
        left: 100%;
        z-index: 99;
        overflow-y: auto;
        transition: all .3s;
        box-sizing: border-box;
        background-color: $color-main;
        text-transform: uppercase;
        @include res(padding-top,$header-height-base,$header-height-ratio);
        &.active{
            transform: translateX(-100%);
        }
        ul{
            @include res(padding-top,50px, 50 / 50);
            @include res(padding-bottom,30px, 20 / 30);
            @include res(padding-left,30px, 20 / 30);
            @include res(padding-right,30px, 20 / 30);
            li{
                &:not(:last-child){
                    @include res(margin-bottom,30px, 30 / 30);
                }
                a{
                    display: block;
                    color: #000;
                    font-family: 'lato-black';
                    @include res(font-size,26px, 24 / 26);
                }
            }
        }
    }
}
