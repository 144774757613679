@font-face {
  font-family: "iconfont"; /* Project id 2978325 */
  src: url('iconfont.woff2?t=1639984471148') format('woff2'),
       url('iconfont.woff?t=1639984471148') format('woff'),
       url('iconfont.ttf?t=1639984471148') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-gaosu:before {
  content: "\e603";
}

.icon-chengshi:before {
  content: "\e604";
}

.icon-lianghao:before {
  content: "\e605";
}

.icon-shanqu:before {
  content: "\e607";
}

.icon-chengji:before {
  content: "\e608";
}

.icon-xueshanrenzheng:before {
  content: "\e609";
}

.icon-hunhe:before {
  content: "\e60a";
}

.icon-kuangqu:before {
  content: "\e60b";
}

.icon-a-MS:before {
  content: "\e60c";
}

.icon-dongji:before {
  content: "\e60d";
}

.icon-icon-luntai:before {
  content: "\e671";
}

.icon-xiangshang:before {
  content: "\e61f";
}

.icon-24gf-playCircle:before {
  content: "\ea82";
}

.icon-search_light:before {
  content: "\e7da";
}

.icon-jiantou_xia:before {
  content: "\eb04";
}

.icon-jiantou_zuo:before {
  content: "\eb05";
}

.icon-arrow-right-bottom:before {
  content: "\e781";
}

.icon-world:before {
  content: "\e620";
}

.icon-facebook-fill:before {
  content: "\e88d";
}

.icon-linkedin-fill:before {
  content: "\e890";
}

.icon-jiantou_you:before {
  content: "\eb03";
}

.icon-changyongtubiao-fuben-18:before {
  content: "\e606";
}

