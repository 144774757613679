.nybanner {
  display: flex;
  align-items: center;
  text-align: right;
  position: relative;
  overflow: hidden;
  height: 570px;
}

@media (max-width: 1024px) {
  .nybanner {
    height: 400px;
  }
}

@media (max-width: 991px) {
  .nybanner {
    height: 300px;
  }
}

@media (max-width: 767px) {
  .nybanner {
    height: 200px;
  }
}

.nybanner::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.nybanner b {
  display: block;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.nybanner .container {
  position: relative;
  z-index: 1;
}

.nybanner .container h1 {
  font-family: 'lato-black';
  color: #fabe00;
  font-size: 100px;
}

@media (max-width: 1600px) {
  .nybanner .container h1 {
    font-size: 86.8px;
  }
}

@media (max-width: 1366px) {
  .nybanner .container h1 {
    font-size: 73.6px;
  }
}

@media (max-width: 1024px) {
  .nybanner .container h1 {
    font-size: 60.4px;
  }
}

@media (max-width: 991px) {
  .nybanner .container h1 {
    font-size: 47.2px;
  }
}

@media (max-width: 767px) {
  .nybanner .container h1 {
    font-size: 34px;
  }
}

.nybanner .container h2 {
  font-weight: bold;
  color: #fff;
  font-size: 40px;
}

@media (max-width: 1600px) {
  .nybanner .container h2 {
    font-size: 35.6px;
  }
}

@media (max-width: 1366px) {
  .nybanner .container h2 {
    font-size: 31.2px;
  }
}

@media (max-width: 1024px) {
  .nybanner .container h2 {
    font-size: 26.8px;
  }
}

@media (max-width: 991px) {
  .nybanner .container h2 {
    font-size: 22.4px;
  }
}

@media (max-width: 767px) {
  .nybanner .container h2 {
    font-size: 18px;
  }
}

.nybanner #nav-md {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 145px;
}

@media (max-width: 1600px) {
  .nybanner #nav-md {
    height: 128px;
  }
}

@media (max-width: 1366px) {
  .nybanner #nav-md {
    height: 111px;
  }
}

@media (max-width: 1024px) {
  .nybanner #nav-md {
    height: 94px;
  }
}

@media (max-width: 991px) {
  .nybanner #nav-md {
    height: 77px;
  }
}

@media (max-width: 767px) {
  .nybanner #nav-md {
    height: 60px;
  }
}
